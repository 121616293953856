<template>
   <div class="vap-page">
      <Loading
        :active.sync="isLoading"
        :is-full-page="false"
        :color="loaderColor"
        loader="dots"
        :background-color="loaderBackgroundColor"
      />
      <div class="vap-page-header">
        <h1>{{ schedulerTitle }}</h1>
      <div class="btns-container">
        <VasionButton
        id="save-button"
        name="save-button"
        classProp="primary"
        :isDisabled="disableSaveButton"
        @vasionButtonClicked="save()"
      >
        Save
      </VasionButton>

      <VasionButton
        id="cancel-button"
        class="last-btn"
        name="cancel-button"
        classProp="secondary"
        @vasionButtonClicked="cancel()"
      >
        Cancel
      </VasionButton>
      </div>
    </div>
    <div class="vap-page-content">
        <AutomationEngineSharedHeader
          ref="header"
          :nameLabel="'EXPORT NAME'"
          @permissions="showMainSection = false"
          @general-automation="showMainSection = true"
          @dirty="isDirty = true"
        />

        <!-- Build Export -->
        <div v-if="showMainSection" id="build-export" class="collapsable-section">
          <h2 class="subheader">
            Build Export
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showBuildExport ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('build-export')"
          />

          <div v-show="showBuildExport">
            <div class="row">
              <VasionInput
                id="export-from"
                v-model="exportFromFolder"
                class="row-field browse-input"
                title="FOLDER TO EXPORT"
                inputType="top-white"
                name="export-from"
                placeholder="Select Folder..."
                :required="true"
                :width="'306'"
              />
              <VasionButton
                id="export-from-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('export-from-folder-button')"
              >
                Browse
              </VasionButton>

              <VasionDropList
                v-slot="slotItem"
                v-model="configuration"
                class="row-field"
                :dataArray="configurationList"
                width="396"
                title="EXPORT CONFIGURATION"
                placeholder="Select Configuration..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
            </div>
            <div class="row">
              <VasionDropList
                class="row-field"
                v-slot="slotItem"
                v-model="exportToSource"
                :required="true"
                :dataArray="exportToSourceOptions"
                :title="'EXPORT TO'"
                :type="'plain-list'"
                :valueName="'name'"
                displayName="name"
                width="410"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>
              <VasionInput
                v-if="exportToCloudStorage"
                id="to-cloud-folder"
                v-model="exportToThirdPartyFolderName"
                class="row-field"
                title="FOLDER"
                inputType="top-white"
                name="to-cloud-folder"
                placeholder="Select Folder..."
                :width="'285'"
                :readonly="true"
                :required="true"
                @input="isDirty = true"
              />
              <VasionButton
                v-if="exportToCloudStorage"
                id="export-to-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('export-to-folder-button')"
              >
                Browse
              </VasionButton>

              <VasionInput
                v-if="exportToExternalSource"
                id="to-external-folder"
                v-model="exportToFolderPath"
                class="row-field"
                title="FOLDER"
                inputType="top-white"
                name="to-cloud-folder"
                placeholder="Enter Folder Path..."
                :width="'396'"
                :required="true"
                @input="isDirty = true"
              />
            </div>

            <div v-if="configuration.value === 0" class="row">
              <VasionCheckbox
                id="move-to-folder-after-export"
                name="move-to-folder-after-export"
                class="export-checkbox"
                :checked="moveToFolderAfterExport"
                @change="moveToFolderAfterExportChanged"
              >
                Move to Folder After Export
              </VasionCheckbox>

              <VasionCheckbox
                id="delete-documents-after-export"
                name="delete-documents-after-export"
                class="export-checkbox"
                :checked="deleteDocumentsAfterExport"
                @change="deleteDocumentsAfterExportChanged"
              >
                Delete Documents After Export
              </VasionCheckbox>
            </div>

            <div v-if="moveToFolderAfterExport && configuration.value === 0" class="row">
              <VasionInput
                id="move-to"
                v-model="moveToFolder"
                class="row-field browse-input"
                title="MOVE TO"
                inputType="top-white"
                name="move-to"
                placeholder="Select Folder..."
                :width="'306'"
                @input="isDirty = true"
              />
              <VasionButton
                id="move-to-folder-button"
                class="browse-button"
                :classProp="'primary'"
                @vasionButtonClicked="toggleBrowseFolderDialog('move-to-folder-button')"
              >
                Browse
              </VasionButton>
            </div>

            <div v-if="configuration.value === 0" class="row">
              <VasionCheckbox
                id="export-documents"
                name="export-documents"
                class="export-checkbox"
                :checked="exportDocuments"
                @change="toggleCheckbox('exportDocuments')"
              >
                Export Documents
              </VasionCheckbox>

              <VasionCheckbox
                id="include-subfolder"
                name="include-subfolder"
                class="export-checkbox"
                :checked="includeSubfolder"
                @change="toggleCheckbox('includeSubfolder')"
              >
                Include Subfolders
              </VasionCheckbox>
            </div>

            <div v-if="configuration.value === 0" class="row">
              <VasionCheckbox
                id="export-to-csv"
                name="export-to-csv"
                class="export-checkbox"
                :checked="exportToCsv"
                @change="toggleCheckbox('exportToCsv')"
              >
                Export Object data to CSV
              </VasionCheckbox>

              <VasionCheckbox
                v-if="featureTextractLineItem"
                id="export-line-item"
                name="export-line-item"
                class="export-checkbox"
                :checked="exportLineItemData"
                @change="toggleCheckbox('exportLineItemData')"
              >
                Export Line item/Table data
              </VasionCheckbox>
            </div>

            <div v-if="(exportToCsv || exportLineItemData) && configuration.value === 0" class="row">
              <VasionInput
                id="csv-file-name"
                v-model="csvFileName"
                class="row-field browse-input"
                title="CSV FILE NAME"
                inputType="top-white"
                name="csv-file-name"
                placeholder="Enter Name..."
                :width="'396'"
                @input="isDirty = true"
              />

              <VasionCheckbox
                id="export-document-id-in-csv-file"
                name="export-document-id-in-csv-file"
                class="export-checkbox extra-top-margin"
                :checked="exportDocumentIdInCsvFile"
                @change="toggleCheckbox('exportDocumentIdInCsvFile')"
              >
                Export Document ID in CSV File
              </VasionCheckbox>
            </div>

            <div v-if="showObjectDataToExportRadio" class="export-fields-radio-group">
              <md-radio
                id="export-all-data"
                v-model="objectDataToExport"
                :value="0"
                @change="isDirty=true"
              />
              <label for="export-all-data" class="export-fields-radio-label">Export All Object Data</label>
              <md-radio
                id="export-folder-data"
                v-model="objectDataToExport"
                :value="1"
                @change="isDirty=true"
              />
              <label for="export-folder-data" class="export-fields-radio-label">Export Object Data Based on Folder View</label>
            </div>
          </div>
        </div>

        <!-- Exported Subfolders -->
        <div v-if="configuration.value === 0 && showMainSection" id="exported-subfolders" class="collapsable-section">
          <h2 class="subheader">
            Exported Subfolders
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showExportedSubfolders ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('exported-subfolders')"
          />

          <div v-show="showExportedSubfolders && configuration.value === 0">
            <div class="row">
              <VasionCheckbox
                id="export-subfolder-structure"
                name="export-subfolder-structure"
                class="export-checkbox"
                :checked="exportSubfolderStructure"
                @change="toggleCheckbox('exportSubfolderStructure')"
              >
                Maintain Folder Structure
              </VasionCheckbox>
            </div>

            <div class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="subFolderAttributeField"
                class="row-field"
                :isDisabled="exportSubfolderStructure"
                :dataArray="attributeFieldList"
                width="396"
                title="FIELDS"
                placeholder="Select Fields..."
                type="plain-list"
                displayName="name"
                valueName="value"
                :openAbove="showBuildExport"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionButton
                id="add-field-to-subfolder-path"
                class="browse-button"
                :classProp="'secondary'"
                :isDisabled="exportSubfolderStructure"
                @vasionButtonClicked="addFieldToSubFolderPath()"
              >
                Add
              </VasionButton>
            </div>

            <div class="row">
              <VasionInput
                id="subfolder-path"
                v-model="subFolderPath"
                class="row-field browse-input"
                :isDisabled="exportSubfolderStructure"
                title="SUBFOLDER PATH"
                inputType="top-white"
                name="subfolder-path"
                placeholder="Enter Path..."
                :width="'396'"
              />
            </div>
          </div>
        </div>

        <!-- Custom Naming -->
        <div v-if="configuration.value === 0 && showMainSection" id="custom-naming" class="collapsable-section">
          <h2 class="subheader">
            Custom Naming
          </h2>

          <VasionButton
            name="collapse-section"
            class="collapse-section"
            :icon="showCustomNaming ? 'VasionArrowDropDownIcon' : 'VasionArrowDropDownRight16Icon'"
            title="Collapse Section"
            @vasionButtonClicked="toggleCollapsableSection('custom-naming')"
          />

          <div v-show="showCustomNaming && configuration.value === 0">
            <div class="row">
              <VasionCheckbox
                id="use-custom-naming"
                name="use-custom-naming"
                class="export-checkbox"
                :checked="useCustomNaming"
                @change="toggleCheckbox('useCustomNaming')"
              >
                Use Custom Naming
              </VasionCheckbox>
            </div>

            <div v-if="useCustomNaming" class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="customNamingAttributeField"
                class="row-field"
                :dataArray="attributeFieldList"
                width="396"
                title="FIELDS"
                placeholder="Select Fields..."
                type="plain-list"
                displayName="name"
                valueName="value"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionButton
                id="add-field-to-subfolder-path"
                class="browse-button"
                :classProp="'secondary'"
                @vasionButtonClicked="addFieldToCustomName()"
              >
                Add
              </VasionButton>
            </div>

            <div v-if="useCustomNaming" class="row">
              <VasionInput
                id="custom-name"
                v-model="customName"
                class="row-field browse-input"
                title="CUSTOM NAME"
                inputType="top-white"
                name="custom-name"
                placeholder="Enter Name..."
                :width="'396'"
              />
            </div>

            <div class="row">
              <VasionCheckbox
                id="add-label-to-documents"
                name="add-label-to-documents"
                class="export-checkbox"
                :checked="addLabelToDocuments"
                @change="toggleCheckbox('addLabelToDocuments')"
              >
                Add Label to Documents
              </VasionCheckbox>
            </div>

            <div v-if="addLabelToDocuments" class="row">
              <VasionInput
                id="label-text"
                v-model="labelText"
                class="row-field browse-input"
                title="LABEL TEXT"
                inputType="top-white"
                name="label-text"
                placeholder="Enter Label..."
                :width="'396'"
                @input="isDirty = true"
              />
            </div>

            <div v-if="addLabelToDocuments" class="row">
              <VasionDropList
                v-slot="slotItem"
                v-model="location"
                class="row-field"
                :dataArray="locationList"
                width="396"
                title="LOCATION"
                placeholder="Select Location..."
                type="plain-list"
                displayName="name"
                valueName="value"
                @input="isDirty = true"
              >
                {{ slotItem.item.name }}
              </VasionDropList>

              <VasionCheckbox
                id="include-document-id"
                name="include-document-id"
                class="export-checkbox extra-top-margin"
                :checked="includeDocumentId"
                @change="toggleCheckbox('includeDocumentId')"
              >
                Include Document ID
              </VasionCheckbox>
            </div>
          </div>
        </div>
      </div>

    <md-dialog id="browse-folders-dialog" :md-active.sync="showBrowseFoldersDialog" :md-click-outside-to-close="false">
      <VasionFolderSelect
        title="Select Folder..."
        okButtonText="Select"
        :showOnlyDataAutomation="fileBrowseField === 'export-to-folder-button'"
        @cancelButtonClick="toggleBrowseFolderDialog"
        @okButtonClick="folderDialogOK"
      />
    </md-dialog>

    <md-dialog id="confirmLeave" :md-active.sync="showLeaveDialog" @md-clicked-outside="clickedOutsideLeave()">
      <VasionConfirmationDialog
        message="Are you sure you want to leave? Changes have not been saved."
        @noButtonClick="cancelLeave()"
        @yesButtonClick="doLeave()"
      />
    </md-dialog>

    <VasionSnackbar
      id="export-snack"
      :showSnackbarBool.sync="showSnackbarBool"
      :snackbarImage="snackbarImage"
      :snackbarSubTitle="snackbarSubTitle"
      :snackbarTitle="snackbarTitle"
    />
  </div>
</template>

<script>
import AutomationEngineSharedHeader from '@/views/admin/automationEngine/AutomationEngineSharedHeader.vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { loaderBackgroundColor, loaderColor } from '@/assets/js/styleConfig'

export default {
  name: 'EditExport',
  components: {
    AutomationEngineSharedHeader,
    Loading,
  },
  beforeRouteLeave(to, from, next) {
    if (this.isDirty && !this.routeTo) {
      this.routeTo = to
      this.toggleLeaveDialog()
    } else {
      this.routeTo = null
      next()
    }
  },
  data: function () {
    return {
      addLabelToDocuments: false,
      configuration: '',
      csvFileName: '',
      customName: '',
      customNamingAttributeField: false,
      deleteDocumentsAfterExport: false,
      emailWhenFinished: '',
      exportDocumentIdInCsvFile: false,
      exportDocuments: false,
      objectDataToExport: 0,
      exportSubfolderStructure: false,
      exportFromFolder: '',
      exportFromFolderId: 0,
      exportFromFolderName: '',
      exportId: 0,
      exportLineItemData: false,
      exportToThirdPartyStorageConfigId: null,
      exportToThirdPartyFolderId: '',
      exportToThirdPartyFolderName: '',
      exportToCsv: false,
      exportToFolderPath: '',
      exportToSource: {},
      exportToSourceOptions: [
        {name: 'Cloud Storage'},
        {name: 'External Source'},
      ],
      featureTextractLineItem: false,
      fileBrowseField: '',
      includeDocumentId: false,
      includeSubfolder: false,
      isDirty: false,
      isLoading: false,
      labelText: '',
      loaderBackgroundColor,
      loaderColor,
      location: '',
      moveToFolder: '',
      moveToFolderAfterExport: false,
      moveToFolderId: 0,
      moveToFolderName: '',
      saveLocked: false,
      schedulerTitle: '',
      sendToWorkflow: '',
      showBrowseFoldersDialog: false,
      showBuildExport: false,
      showCustomNaming: false,
      showExportedSubfolders: false,
      showLeaveDialog: false,
      showMainSection: true,
      showSnackbarBool: false,
      snackbarImage: false,
      snackbarSubTitle: '',
      snackbarTitle: '',
      subFolderAttributeField: '',
      subFolderPath: '',
      useCustomNaming: false,
    }
  },
  watch: {
    exportToSource(newVal, oldVal) {
      if (newVal.name !== oldVal.name && oldVal?.name) {
        // reset selected folder when exportToSource is changed.
        this.exportToThirdPartyStorageConfigId = null
        this.exportToThirdPartyFolderId = ''
        this.exportToThirdPartyFolderName = ''
      }
    },
  },
  // eslint-disable-next-line vue/order-in-components
  computed: {
    activeScheduler() { return this.$store.state.automationEngine.activeScheduler },
    attributeFieldList() { return this.$store.state.storage.uniqueFieldNames },
    configurationList() {
      const configList = this.$store.state.automationEngine.exportConfigList
      let configs = [{ name: 'Manual Export', value: 0 }]

      if (configList && configList.length > 0) {
        configs = configList.map((c) => {
          return {
            name: c.name,
            value: c.exportConfigId,
            exportToCsv: c.csvName?.length > 0
          }
        })

        configs.unshift({
          name: 'Manual Export',
          value: 0,
        })
      }

      return configs
    },
    disableSaveButton() { return this.saveLocked || !this.isDirty },
    exportToExternalSource() { return this.exportToSource.name === 'External Source' },
    exportToCloudStorage() { return this.exportToSource.name === 'Cloud Storage' },
    locationList() {
      return [
        { name: 'None', value: 0 },
        { name: 'Top Left', value: 1 },
        { name: 'Top Center', value: 2 },
        { name: 'Top Right', value: 3 },
        { name: 'Bottom Left', value: 4 },
        { name: 'Bottom Center', value: 5 },
        { name: 'Bottom Right', value: 6 }]
    },
    showObjectDataToExportRadio() {
      return (this.exportToCsv && (this.exportLineItemData || !this.exportLineItemData)) || this.configurationList.some(c => c.value === this.configuration.value && c.exportToCsv)
    }
  },
  async created() {
    this.isLoading = true

    await Promise.all([
      this.$store.dispatch('automationEngine/clearActiveScheduler'),
      this.$store.dispatch('automationEngine/getExportConfigList'),
    ])

    if (this.$route.params.schedulerId && parseInt(this.$route.params.schedulerId, 10) > 0) {
      await this.$store.dispatch('automationEngine/getExportDetails', parseInt(this.$route.params.schedulerId, 10))
      if (this.activeScheduler) {
        this.setExportValues()
      }
    } else {
      this.configuration = { name: 'Manual Export', value: 0 }
    }
    this.setHeaderDetails(this.exportId, this.activeScheduler?.runDetails)
    this.schedulerTitle = this.exportId && this.exportId > 0 && this.activeScheduler && this.activeScheduler.runDetails ? this.activeScheduler.runDetails.name : 'Untitled Export'
    this.$store.dispatch('storage/loadUniqueFieldNames')
    this.moveToFolderAfterExport = this.moveToFolderId !== 0 && this.moveToFolderId > 0
    this.addLabelToDocuments = this.labelText !== ''

    this.featureTextractLineItem = this.$store.state.systemSettings.featureFlags.textractLineItemDataEnabled

    this.isLoading = false
  },
  methods: {
    addFieldToCustomName() {
      this.isDirty = true
      this.customName = `${this.customName}<%${this.customNamingAttributeField.name}%>`
    },
    addFieldToSubFolderPath() {
      this.isDirty = true
      this.subFolderPath = `${this.subFolderPath}<%${this.subFolderAttributeField.name}%>`
    },
    cancel() { this.$router.push({ name: 'ImportExport' }) },
    cancelLeave() {
      this.routeTo = null
      this.toggleLeaveDialog()
    },
    clickedOutsideLeave() { this.routeTo = null },
    async doLeave() {
      this.toggleLeaveDialog()
      this.$router.push({ path: this.routeTo.path })
    },
    deleteDocumentsAfterExportChanged() {
      this.deleteDocumentsAfterExport = !this.deleteDocumentsAfterExport
      if (this.deleteDocumentsAfterExport && this.moveToFolderAfterExport) {
        this.moveToFolderAfterExportChanged()
      }
      this.isDirty = true
    },
    folderDialogOK(payload) {
      this.isDirty = true

      switch(this.fileBrowseField){
        case 'export-from-folder-button':
          this.exportFromFolderId = payload.SelectedFolderID
          this.exportFromFolderName = payload.SelectedFolderName
          this.exportFromFolder = this.exportFromFolderName
          break
        case 'move-to-folder-button':
          this.moveToFolderId = payload.SelectedFolderID
          this.moveToFolderName = payload.SelectedFolderName
          this.moveToFolder = this.moveToFolderName
          break
        case 'export-to-folder-button':
          this.exportToThirdPartyStorageConfigId = payload.SelectedDriveType.storageConfigId;
          this.exportToThirdPartyFolderId = payload.SelectedFolderID;
          this.exportToThirdPartyFolderName = payload.SelectedFolderName;
          break
      }

      this.toggleBrowseFolderDialog()
    },
    moveToFolderAfterExportChanged() {
      this.moveToFolderAfterExport = !this.moveToFolderAfterExport
      if (!this.moveToFolderAfterExport) {
          this.moveToFolderId = 0
          this.moveToFolderName = null
          this.moveToFolder = null
      } else if (this.moveToFolderAfterExport && this.deleteDocumentsAfterExport) {
        this.deleteDocumentsAfterExportChanged()
      }
      this.isDirty = true
    },
    async save() {
      this.saveLocked = true
      const { header } = this.$refs
      if (await this.validate(header)) {
        const payload = {
          runDetails: await header.getValues(),
          exportConfigID: this.configuration.value > 0 ? this.configuration.value : null,
          folderID: this.exportFromFolderId,
          moveToFolderID: this.moveToFolderId,
          exportCSV: this.exportToCsv,
          exportDocuments: this.exportDocuments,
          objectDataToExport: this.objectDataToExport,
          useCustomNaming: this.useCustomNaming,
          csvFileName: this.csvFileName,
          subFolderPath: (this.exportSubfolderStructure) ? '' : this.subFolderPath,
          customName: this.customName,
          deleteAfterExport: this.deleteDocumentsAfterExport,
          labelText: this.labelText,
          labelDocumentID: this.includeDocumentId,
          labelLocation: this.location.value,
          exportDocID: this.exportDocumentIdInCsvFile,
          exportPath: this.exportToExternalSource ? this.exportToFolderPath : '',
          exportToStorageConfigId: this.exportToCloudStorage ? this.exportToThirdPartyStorageConfigId : null,
          exportToThirdPartyFolderId: this.exportToCloudStorage ? this.exportToThirdPartyFolderId : '',
          includeSubfolder: this.includeSubfolder,
          exportSubfolderStructure: this.exportSubfolderStructure,
          exportLineItemData: this.exportLineItemData,
        }

        if (!await this.$store.dispatch('automationEngine/saveExportScheduler', payload)) {
          this.saveLocked = false
          this.snackbarTitle = 'ERROR'
          this.snackbarSubTitle = 'There was an error saving the export.'
          this.snackbarImage = false
        } else {
          this.isDirty = false
          this.saveLocked = false
          this.snackbarTitle = 'SUCCESS'
          this.snackbarSubTitle = 'Export saved successfully.'
          this.snackbarImage = true
          this.$router.push({ name: 'ImportExport' })
        }
        this.showSnackbarBool = true
      }
    },
    setExportValues() {
      this.exportId = this.activeScheduler.runDetails.schedulerID
      this.exportFromFolder = this.activeScheduler.folderName
      this.exportFromFolderId = this.activeScheduler.folderID
      this.exportToThirdPartyStorageConfigId = this.activeScheduler.exportToStorageConfigId
      this.exportToThirdPartyFolderId = this.activeScheduler.exportToThirdPartyFolderId
      this.exportToThirdPartyFolderName = this.activeScheduler.exportToFolderName
      this.exportToSource = { name: this.exportToThirdPartyFolderId ? 'Cloud Storage' : 'External Source' }
      this.moveToFolder = this.activeScheduler.moveToFolderName
      this.moveToFolderId = this.activeScheduler.moveToFolderID
      this.exportToFolderPath = this.activeScheduler.exportPath
      this.exportToCsv = this.activeScheduler.exportCSV
      this.exportDocuments = this.activeScheduler.exportDocuments
      this.objectDataToExport = this.activeScheduler.objectDataToExport
      this.useCustomNaming = this.activeScheduler.useCustomNaming
      this.csvFileName = this.activeScheduler.csvFileName
      this.subFolderPath = this.activeScheduler.subFolderPath
      this.customName = this.activeScheduler.customName
      this.deleteDocumentsAfterExport = this.activeScheduler.deleteAfterExport
      this.labelText = this.activeScheduler.labelText
      this.includeDocumentId = this.activeScheduler.labelDocumentID
      this.location = this.locationList.find(l => l.value === this.activeScheduler.labelLocation) || ''
      this.exportDocumentIdInCsvFile = this.activeScheduler.exportDocID
      this.includeSubfolder = this.activeScheduler.includeSubfolder
      this.exportSubfolderStructure = this.activeScheduler.exportSubfolderStructure
      this.exportLineItemData = this.activeScheduler.exportLineItemData
      this.configuration = this.configurationList.find(c => c.value === this.activeScheduler.exportConfigID) || ''
    },
    setHeaderDetails(id, details) {
      const { header } = this.$refs
      if (id > 0) {
        header.setValues(details)
      } else {
        header.setValues()
      }
    },
    toggleBrowseFolderDialog(field) {
      this.showBrowseFoldersDialog = !this.showBrowseFoldersDialog

      this.fileBrowseField = ''
      if (this.showBrowseFoldersDialog && field && field !== '') {
        this.fileBrowseField = field
      }
    },
    toggleCheckbox(id) {
      this[id] = !this[id]
      this.isDirty = true
    },
    toggleCollapsableSection(section) {
      switch (section) {
        case 'build-export':
          this.showBuildExport = !this.showBuildExport
          break;
        case 'exported-subfolders':
          this.showExportedSubfolders = !this.showExportedSubfolders
          break;
        case 'custom-naming':
          this.showCustomNaming = !this.showCustomNaming
          break;
        default:
          this.showBuildExport = false
          this.showExportedSubfolders = false
          break;
      }
    },
    toggleLeaveDialog() { this.showLeaveDialog = !this.showLeaveDialog },
    async validate(header) {
      let error = false
      let errorText = ''
      this.snackbarTitle = 'ERROR'
      this.snackbarImage = false

      await header.validate()
      if (header.errors !== '') {
        error = true
        errorText += `${header.errors}`
      }

      if (this.exportFromFolderId <= 0) {
        error = true
        errorText += '- Please provide a value for "Folder to Export."\n'
      }

      if (this.configuration.value === 0 && !this.exportDocuments && !this.exportToCsv) {
        error = true
        errorText += '- Either "Export Documents" or "Export Object data to CSV" must be checked.\n'
      }

      if (!this.exportToCsv && this.exportLineItemData) {
        error = true
        errorText += '- For "Export Line item/Table data" the "Export Object data to CSV" must be checked.\n'
      }

      if(this.exportToCloudStorage && !this.exportToThirdPartyStorageConfigId){
        error = true
        errorText += '- You must select a folder to export to.\n'
      }
      else if (this.exportToExternalSource) {
        if(this.exportToFolderPath === ''){
          error = true
          errorText += '- Please provide a value for "Folder".\n'
        }
        else if(!await this.$store.dispatch('automationEngine/getIsValidUncPath', { Value: this.exportToFolderPath })){
          error = true
          errorText += '- The value of "Folder" is not a valid UNC path.\n'
        }
      }

      if (error) {
        this.snackbarSubTitle = errorText
        this.showSnackbarBool = true
        this.saveLocked = false
        return false
      }

      return true
    },
  }
}
</script>

<style lang="scss" scoped>
  @import '@/assets/css/variables.scss';
  @import '@/assets/css/light-theme-system-palette.scss';

  .subheader {
    @include SubHeadline;
  }
  .row {
      width: 100%;
      margin: 13px 0;
      display: flex;
  }
  .row-field {
    margin: 0 14px 5px 0;

    &.checkbox {
      width: 390px;
    }
  }

  .browse-input {
    margin-right: 5px;
  }

  .browse-button {
    button {
      margin-top: 22px;
      margin-left: 0;
      margin-right: 13px;
    }
  }

  .vasion-checkbox {
    &.extra-top-margin {
      margin-top: 28px;
    }
  }

  .export-checkbox {
    width: 408px;
  }

  .collapsable-section {
    float: left;
    margin-top: 20px;
    width: 100%;

    .subheader {
      @include SubHeadline;
      width: 225px;
      display: block;
      float: left;
      margin-top: 5px;
    }

    .subtitle {
      @include Subtitle;
    }
  }

  #export-from-folder-button {
    margin-right: 8px;
  }

 .browse-button {
    margin-top: 17px;
  }

  .export-fields-radio-group {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .export-fields-radio-label {
    font-weight: normal;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    font-family: $font-family-book;
    color: $grey-400;
    margin: 0 40px 0 0;
  }

  .export-fields-radio-label:hover {
    cursor: pointer;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-ripple {
    color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container {
    border-color: $secondary !important;
  }
  ::v-deep .md-radio.md-theme-default.md-checked .md-radio-container:after {
    background-color: $secondary !important;
  }
</style>
